export const formatCurrency = (int) => {
    var formatter = new Intl.NumberFormat("en-US");

    return formatter.format(int);
}
export default formatCurrency;


export const converStatus = (status) => {
    let res = "";
    switch (status) {
        case "INSTOCK":
            res = "ພ້ອມສົ່ງ";
            break;
        case "PRE_ORDER":
            res = "ພຣີອໍເດີ";
            break;
        case "OUT_ORDER":
            res = "ໝົດສະຕ໋ອກ";
            break;
    }
    return res;
};
