import React, { useState, useEffect } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';


export default function CustomerInfo({ customerInfomation, handleClose, handleConfirmOrder, setInfoCustomer }) {

    //state
    const [fullname, setFullname] = useState()
    const [phone, setPhone] = useState()
    const [address, setAddress] = useState()


    //function

    const notifyError = (text) => toast.error(text, { autoClose: 1000, });

    const onConfrim = async () => {
        if (!fullname) return notifyError("ກະລຸນາຕື່ມຊື່");
        if (!phone) return notifyError("ກະລຸນາຕື່ມເບີໂທ");
        if (!address) return notifyError("ກະລຸນາຕື່ມທີ່ຢູ່");

        let _data = {
            fullname: fullname,
            phone: phone.toString(),
            address: address
        }
        // await setInfoCustomer(_data)
        await handleConfirmOrder(_data)
        await handleClose()
    }

    return (
        <div>
            <Dialog open={customerInfomation} onClose={handleClose} maxWidth="sm" fullWidth={true} >
                <DialogContent style={{ background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)" }}>
                    <div style={{ textAlign: "center", color: "#ffffff", fontWeight: "bold" }}>
                        <p style={{ fontSize: 26 }}>ກະ​ລຸ​ນາປ້ອນ​ຂໍ້​ມູນ​ລຸ່ມ​ນີ້</p>
                        <p style={{ fontSize: 20 }}>ເພື່ອຢືນ​ຢັນ​ຕົວ​ຕົນ​ຂອງ​ທ່ານ</p>
                    </div>

                    <center>
                        <input type="text" className='infoBox' onChange={(e) => setFullname(e.target.value)} placeholder="ກະ​ລຸ​ນາ, ປ້ອນ​ຊື່ ແລະ ນາມ​ສະ​ກຸນ​ຂອງ​ທ່ານ" />
                        <div style={{ height: 10 }}></div>
                        <input type="number" className='infoBoxNumber' max="15" onChange={(e) => setPhone(e.target.value)} placeholder="ເບີ​ໂທ​ລະ​ສັບ​ຕິດ​ຕໍ່ ຕົວ​ຢ່າງ 020 xxxx xxxx" />
                        <div style={{ height: 10 }}></div>
                        <textarea type="text" className='infoBox' onChange={(e) => setAddress(e.target.value)} placeholder="ສະ​ຖານ​ທີ່​ຈັດ​ສົ່ງ (ລະ​ບຸ​ໃຫ້​ລະ​ອຽດ)" />
                        <div style={{ height: 10 }}></div>

                        <button type="button" className='cancelBtn' onClick={handleClose}>ຍົກເລີກ</button>
                        &nbsp;
                        <button type="button" className='confirmBtn' onClick={() => onConfrim()}>ຕົກລົງ</button>
                    </center>

                </DialogContent>
            </Dialog>
        </div>
    );
}
