import React, { useState, useEffect } from 'react'
import axios from "axios";

// import Navbar from "../../layout/Navbar"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useParams } from "react-router-dom";
import * as _ from "lodash";
import CustomerInfo from "./CustomerInfo"

//function
import { formatCurrency } from "../../hooks"



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { styled } from '@mui/material/styles';
import { Slide, Badge, Grid, Box, Paper, Drawer, Stack, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Typography from '@mui/material/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


import { CUSTOMER_CREATE_ORDER, STORE_INFO } from "../../constant/routes"
import { BACKEND_URL, IMAGE_URL, STORE_PRODUCT } from "../../constant/"

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';

const Header = styled(Box)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
}));

const AppBarHead = styled(AppBar)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
    position: "static",
    // minHeight: "50px",
}));

const Order = styled(Paper)(({ }) => ({
    marginTop: 10,
    width: '100%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10
}));


const TotalPrice = styled("div")(({ theme }) => ({
    marginTop: 40,
    width: '100%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

}));
const ContactStore = styled("div")(({ theme }) => ({
    textAlign: "center",
    padding: 15,
    minHeight: 300
}));


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 20,
        //   border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        color: "red",
        background: "#ffffff"

    },
}));



function ConfirmOrder() {
    const Navigate = useNavigate();
    const params = useParams();

    const storeId = params.storeId;
    const [isLoading, setIsLoading] = useState()
    const [storeInfo, setStoreInfo] = useState()

    const [orderChoose, setOrderChoose] = useState([])
    const [amountPrice, setAmountPrice] = useState(0)
    const [confirmOrder, setConfirmOrder] = useState(false);
    const [customerInfomation, setCustomerInfomation] = useState(false)
    const [informationUser, setInformationUser] = useState({ bottom: false });

    useEffect(() => {
        setConfirmOrder(true)
        setIsLoading(true)
        var listOrder = sessionStorage.getItem(STORE_PRODUCT)
        if (listOrder !== null) {
            setOrderChoose(JSON.parse(listOrder))
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
        }

        getStoreInfo(storeId)
    }, [storeId])

    const getStoreInfo = async (storeId) => {

        const responseStore = await axios({
            method: 'get',
            url: BACKEND_URL + STORE_INFO + storeId
        })

        if (responseStore?.data !== null) {
            setStoreInfo(responseStore?.data)
        }

    }

    useEffect(() => {
        if (orderChoose) {
            let sumPrice = 0
            for (var i = 0; i < orderChoose.length; i++) {
                sumPrice += orderChoose[i].totalPrice
            }
            setAmountPrice(sumPrice)
        }
    }, [orderChoose])

    const notifyRemove = () => toast.success("ນຳອອກຈາກກະຕ່າແລ້ວ", { autoClose: 1000, });
    const removeOrder = async (order) => {
        let _order = [...orderChoose]
        _.remove(_order, order)
        setOrderChoose(_order)
        await sessionStorage.setItem(STORE_PRODUCT, JSON.stringify(_order))
        var listOrder = await sessionStorage.getItem(STORE_PRODUCT)
        if (listOrder !== null) setOrderChoose(JSON.parse(listOrder))
        await notifyRemove()
    }


    const handleCloseCustomerInfo = () => setCustomerInfomation(false)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setInformationUser({ ...informationUser, [anchor]: open });
    };
    // const list = (anchor) => (
    //     <Box
    //         sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250, background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)" }}
    //         role="presentation"
    //         onClick={toggleDrawer(anchor, false)}
    //         onKeyDown={toggleDrawer(anchor, false)}
    //     >
    //         <ContactStore>
    //             <p style={{ color: '#ffffff', fontWeight: "bold", fontSize: 24 }}>ຕິດ​ຕໍ່​ຫາ​ເຈ​ົ້າ​ຂອງ​ຮ້ານ</p>
    //             <div style={{ height: 10 }}></div>
    //             <p style={{ color: '#ffffff', fontWeight: "bold", fontSize: 18 }}>ເລືອກຮູບ​ແບບ​ການ​ຕິດ​ຕໍ່​ດ້ານ​ລຸ່ມນີ້</p>
    //             <div style={{ height: 10 }}></div>

    //             <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
    //                 <Button style={{ color: "#ffffff", width: 110, height: 60, background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)", border: "1px solid #FFFFFF", boxShadow: "0px 0px 4px #D9D9D9", borderRadius: "12px" }} ><PhoneIcon /></Button>
    //                 <Button onClick={() => sendToWhatsapp()} style={{ color: "#ffffff", width: 110, height: 60, background: "#4CC85A", border: "1px solid #FFFFFF", boxShadow: "0px 0px 4px #D9D9D9", borderRadius: "12px" }} ><WhatsAppIcon /></Button>
    //                 <Button style={{ color: "#ffffff", width: 110, height: 60, background: "#1877F2", border: "1px solid #FFFFFF", boxShadow: "0px 0px 4px #D9D9D9", borderRadius: "12px" }} ><FacebookIcon /></Button>
    //             </Stack>

    //             <div style={{ height: 40 }}></div>
    //             <p style={{ color: '#ffffff', fontWeight: "bold", fontSize: 16 }} onClick={() => backHome()}>ກັບສູ່ໜ້າຫຼັກ</p>


    //         </ContactStore>
    //     </Box>
    // );


    //function save data customer
    const notifyCreate = (text) => toast.success(text, { autoClose: 1000, });
    const notifyCreateErr = (text) => toast.success(text, { autoClose: 1000, });

    const reloadOrder = () => {
        var listOrder = sessionStorage.getItem(STORE_PRODUCT)
        if (listOrder !== null) setOrderChoose(JSON.parse(listOrder))
    }

    const [dataSend, setDataSend] = useState()
    const [totalOrder, setTotalOrder] = useState(0)
    const handleConfirmOrder = async (data) => {
        setIsLoading(true)

        let preData = {
            ...data,
            storeId: storeId,
            order: orderChoose,
        }

        var orderString = "";
        var sum = amountPrice;
        let _OrderR = [];

        for (let i = 0; i < orderChoose?.length; i++) {
            _OrderR.push(
                (i+1) +
                "ຊື່ສິນຄ້າ: " + orderChoose[i]?.name +
                " ຂະໜາດ: " + orderChoose[i]?.size +
                " ສີ: " + orderChoose[i]?.color +
                " ຮູບ: " + IMAGE_URL + orderChoose[i]?.images +
                " ຈຳນວນ: " + orderChoose[i]?.qty +
                " ລາຄາ: " + formatCurrency(orderChoose[i]?.price) + " ກີບ " +
                " ລວມເງິນ: " + formatCurrency(orderChoose[i]?.totalPrice) + " ກີບ " +
                "\n\n"
            );
        }

        orderString = "ຂໍ້ມູນລູກຄ້າ " + "\n" + " ຊື່ " + data?.fullname + " " + "\n" + " ເບີໂທ " + data?.phone + " " + "\n" + " ບ່ອນສົ່ງ " + data?.address + " " + "\n\n\n";

        for (var i = 0; i < _OrderR.length; i++) {
            orderString = orderString + _OrderR[i];
        }

        orderString = orderString + "\n💵 ລວມລາຄາ: " + formatCurrency(sum) + " ກີບ";

        var config = {
            method: 'post',
            url: BACKEND_URL + CUSTOMER_CREATE_ORDER,
            headers: {
                'Content-Type': 'application/json'
            },
            data: preData
        };

        await axios(config)
            .then(async function (response) {
                if (response?.status === 200) {
                    setIsLoading(false)
                    await setOrderChoose([])
                    await notifyCreate("ດຳເນີນການສຳເລັດ")
                    await sessionStorage.removeItem(STORE_PRODUCT);
                    await reloadOrder();
                    await setTimeout(() => {
                        window.location.href = `https://wa.me/+85620` + storeInfo?.contacts?.whatsapp + `/?text=` + encodeURI(orderString);
                      }, 1000);
                }
            })
            .catch(function (error) {
                notifyCreateErr("ດຳເນີນການບໍ່ສຳເລັດ")
                setIsLoading(false)

            });
    }


    const backHome = () => { Navigate('/store/view-store/limit/50/skip/1/storeId/' + storeId) }
    const continuationOrder = () => { Navigate('/product/confirm-detail/' + storeId) }
    const viewProfile = () => { Navigate('/profile/' + params.storeId) }
    const backHistoryPage = () => { Navigate(-1) }
    return (
        <>
            <Slide direction="left" in={confirmOrder}>
                <Grid container>
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Header sx={{ flexGrow: 1 }} >
                            <AppBarHead sx={{ background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)" }}>
                                <Toolbar style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                                <IconButton
                                        onClick={() => backHistoryPage()}
                                        sx={{ fontWeight: "bold", color: '#ffffff', background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)" }}
                                        aria-label={`back`}
                                    >
                                        <ArrowBackIosNewIcon />
                                    </IconButton>

                                    <IconButton aria-label="cart" onClick={() => continuationOrder()} style={{ color: "#ffffff" }}>
                                        <StyledBadge badgeContent={orderChoose.length > 0 ? orderChoose.length : 0} color="secondary">
                                            <ShoppingCartIcon />
                                        </StyledBadge>
                                    </IconButton>
                                </Toolbar>
                            </AppBarHead>
                        </Header>
                    </Grid>

                    {isLoading ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", minHeight: "70vh" }}>
                            <CircularProgress style={{ color: "#16C73C" }} />
                        </div>
                    ) : (
                        <>
                            <Grid xs={12} sm={6} md={6} lg={6} >
                                <Box sx={{ marginLeft: 1, marginRight: 4 }}>
                                    <p className="h1" style={{ marginTop: 30 }}>ຢືນ​ຢັນ​ການ​ສັ່ງ​ຊື້</p>
                                    {orderChoose && orderChoose.length > 0 ? orderChoose.map((item, index) =>
                                        <Order>
                                            <div style={{ display: 'flex', flexDirection: "row" }}>
                                                <LazyLoadImage src={IMAGE_URL + item?.images} style={{ width: 120, height: 120 }} />
                                                <div style={{ paddingLeft: 10 }}>
                                                    <p className='h2-1'>{item?.name ?? ""}</p>
                                                    <p className='h4'>{item?.price ?? ""} ກີບ</p>
                                                    <p className='h4'>ຂະ​ໜາດ: {item?.size ?? ""}</p>
                                                    <p className='h4'>ສີ: ​{item?.color ?? ""}</p>
                                                    <p className='h4'>ຈຳ​ນວນ: {item?.qty ?? ""} </p>
                                                    <p className='h4'>ລາຄາ: {formatCurrency(item?.price) ?? ""} </p>
                                                    <p className='h4'>ລວມເງິນ: {formatCurrency(item?.totalPrice) ?? ""} </p>

                                                </div>
                                            </div>
                                            <DeleteIcon sx={{ color: "red", cursor: "pointer" }} onClick={() => removeOrder(item)} />
                                        </Order>
                                    ) : (
                                        <Order>
                                            <p style={{ textAlign: "center" }}>ບໍ່ມີລາຍການສັ່ງຊື້</p>
                                        </Order>
                                    )}
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={6} md={6} lg={6} sx={{ padding: { xs: 0, sm: 0, md: 10, lg: 10 } }}>
                                <Box sx={{ padding: 1 }}>
                                    <TotalPrice>
                                        <p className="h2" style={{ marginLeft: 3 }}>ຈຳ​ນວນເງິນ​ທັງ​ໝົ​ດ</p>
                                        <p className="h2" style={{ marginRight: 3 }}>{formatCurrency(amountPrice)} ກີບ</p>
                                    </TotalPrice>
                                    {orderChoose.length > 0 ? (
                                        <>
                                            {/* <button type='button' onClick={() => backHistoryPage()} style={{ marginTop: 10, borderRadius: "20px", fontWeight: "bold", background: "#efefef", width: "30%", border: 0, color: "#9A9A9A", padding: 15, fontSize: 20, fontWeight: "bold", cursor: "pointer" }}>ຍ້ອນກັບ</button>
                                            &nbsp; */}
                                            <button type='button' onClick={() => setCustomerInfomation(true)} style={{ marginTop: 10, borderRadius: "20px", fontWeight: "bold", background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)", width: "100%", border: 0, color: "#ffffff", padding: 15, fontSize: 20, fontWeight: "bold", cursor: "pointer" }}>ຕິດ​ຕໍ່​ຫາ​ເຈ​ົ້າ​ຂອງ​ຮ້ານ</button>
                                        </>
                                    ) : (
                                        <button type='button' onClick={() => backHome()} style={{ marginTop: 10, borderRadius: "20px", fontWeight: "bold", background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)", width: "100%", border: 0, color: "#ffffff", padding: 15, fontSize: 20, fontWeight: "bold", cursor: "pointer" }}>ກັບສູ່ໜ້າຫຼັກ</button>
                                    )}
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} lg={12}>
                                <CustomerInfo customerInfomation={customerInfomation} handleClose={handleCloseCustomerInfo} setInformationUser={setInformationUser} handleConfirmOrder={handleConfirmOrder} />
                            </Grid>
                        </>
                    )}

                    {/* <Grid xs={12} sm={12} md={12} lg={12}>
                        <Drawer
                            anchor={'bottom'}
                            open={informationUser['bottom']}
                            onClose={toggleDrawer('bottom', true)}
                        >
                            {list('bottom')}
                        </Drawer>

                    </Grid> */}

                </Grid>


            </Slide>
            <ToastContainer />

        </>
    )
}

export default ConfirmOrder