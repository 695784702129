import { Outlet } from "react-router-dom";
import ProductsPage from "../pages/product/Products.page";
import ProductsAddPage from "../pages/product/ProductAdd.page";
import ProductDetail from "../pages/product/ProductDetail.page"
import ConfirmOrder from "../pages/product/ConfirmOrder.page"
import StorePage from "../pages/shop/StorePage"


// eslint-disable-next-line
export default {
  path: "store",
  element: <Outlet />,
  children: [
    {
      path: "view-store/limit/:limit/skip/:skip/storeId/:storeId",
      element: <StorePage />,
    },
    {
      path: "add",
      element: <ProductsAddPage />,
    },
    {
      path: "detail",
      element: <ProductDetail />,
    },
    {
      path: "confirm-detail",
      element: <ConfirmOrder />,
    },
  ], 
};
