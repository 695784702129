module.exports = {
    STORE_ID: "STORE_ID",
    USER_KEY: "USER",
    USER_PHONE: "USER_PHONE",
    STORE_PRODUCT: "STORE_PRODUCT",
    USER_DATA_KEY: "USER_DATA",
    IMAGE_URL:"https://bucket-unimarket-files.s3.ap-southeast-1.amazonaws.com/",
    // BACKEND_URL: "http://localhost:8000",
    BACKEND_URL : "https://unitech-server.onrender.com"
    // BACKEND_URL : "https://quaratine-api.lailaolab.com",
    // BACKEND_URL : "https://homeisolation-api.lailaolab.com"
  };
  