module.exports = {
    PRODUCT_TYPE_STORE:'/product-type-store/',

    //product
    PRODUCT_LIST:'/products/',
    PRODUCT_DETAIL:'/product/',

    // customer
    CUSTOMER_CREATE_ORDER:'/customer-create-order',

    // storeId
    STORE_INFO:'/store/',

  };
  