import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import { LazyLoadImage } from "react-lazy-load-image-component";

import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import './Home.css'
import ImageSlide from "../../image/Rectangle.png"
import ImageExampley from '../../image/example.png'
import ImageHover from '../../image/imageHover.png'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const AppBarHead = styled(AppBar)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
    position: "static",
    minHeight: "50px",
}));

const Header = styled(Box)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
    position: "relative",
}));

const BoxSearch = styled("div")(({ theme }) => ({
    padding: 10,
    position: "absolute",
    width: "100%"
}))
const CardFooter = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
}))

const TextHead = styled("div")(({ theme }) => ({
    marginTop: 10,
    display: "flex",
    width: "87%",
    justifyContent: "end"
}));





function Home() {
    const Navigate = useNavigate();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const images = [
        {
            url: ImageSlide,
            caption: 'Slide 1'
        },
        {
            url: ImageSlide,
            caption: 'Slide 2'
        },
        {
            url: ImageSlide,
            caption: 'Slide 3'
        },
    ];

    const properties = {
        prevArrow: <button style={{ display: "none" }}></button>,
        nextArrow: <button style={{ display: "none" }}></button>
    }

    const productLists = [
        {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        },
        {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        },
        {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        },
        {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        },
        {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        },
        {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        },
        {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        }, {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        },
        {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        },
        {
            image: ImageExampley,
            name: "ເສື້ອ​ແຂ​ນ​ຢາວ ສຳຫຼັບ​ທ່ານ​ຊາຍ",
            price: "80.000",
            discount: "50.000",
            status: "ພ້ອມສົ່ງ"
        },

    ]

    const storeId = "63443c949a26daf146c0ce74";

    const viewDatil = () => { Navigate('/product/detail') }
    const viewProfile = () => { Navigate('/profile') }
    const viewStore = () => { Navigate('/store/view-store/' + storeId) }


    return (
        <div>
            <Header sx={{ flexGrow: 1 }} >
                <AppBarHead sx={{ background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)", minHeight: "100px" }}>
                    <Toolbar sx={{ marginTop: { xs: 2.5, sm: 3, md: 1.5, lg: 1.5 }, cursor: 'pointer' }}>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 1, }}
                            onClick={() => viewProfile()}
                        >
                            <AccountCircle sx={{ fontSize: { xs: 25, sm: 20, md: 20, lg: 24 } }} />
                        </IconButton>
                        <Typography variant="h6" onClick={() => viewProfile()} component="div" sx={{ flexGrow: 1, fontSize: { xs: 25, sm: 30, md: 20, lg: 24 }, fontWeight: 700 }}>
                            <p>ຮ້ານ ວັນ​ດີ ແຟ​ຊັ່ນ</p>
                        </Typography>


                       

                        <ShoppingCartIcon onClick={() => viewStore()} sx={{ fontSize: { xs: 25, sm: 20, md: 20, lg: 24 } }} />
                    </Toolbar>
                </AppBarHead>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: { xs: '-4%', sm: "-3%", md: "-1.5%", lg: '-2%' } }}>
                    <input type="text" className='searchBox' placeholder="ຄົ້ນຫາ..." style={{ width: "90%", height: "45px", borderRadius: "16px", boxShadow: "0px 4px 4px rgba(113, 113, 113, 0.09)", background: "#FFFFFF", border: 0 }} />
                </Box>
            </Header>

            <Box style={{ padding: 10 }}>
                <div className="slide-container" style={{ marginTop: 18 }}>
                    <Slide {...properties}>
                        {images.map((slideImage, index) => (
                            <div className="each-slide" key={index}>
                                <LazyLoadImage src={slideImage.url} PlaceholderSrc={ImageHover} style={{ width: '100%', height: '150px', minHeight: 200 }} />
                            </div>
                        ))}
                    </Slide>
                </div>
            </Box>

            <Box style={{ padding: 10 }}>
                <p className="h1" style={{}}>ໝວດສິນຄ້າ</p>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    allowScrollButtonsMobile
                    aria-label="scrollable prevent tabs example"
                >
                    <Tab sx={{ border: 0, fontFamily: "NotoSansLao", background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)", borderRadius: "4px", boxShadow: " 0px 2px 2px rgba(0, 0, 0, 0.06)", minHeight: "24px", maxWidth: "67px", color: "#FFFFFF", fontSize: 18, fontWeight: "400" }} label="ທັງໝົດ" />

                    <Tab style={{ fontFamily: "NotoSansLao" }} label="ເກີບ" />
                    <Tab style={{ fontFamily: "NotoSansLao" }} label="ໝວກ" />
                    <Tab style={{ fontFamily: "NotoSansLao" }} label="ເສື້ອ" />
                    <Tab style={{ fontFamily: "NotoSansLao" }} label="ໂສ້ງ" />
                    <Tab style={{ fontFamily: "NotoSansLao" }} label="ໂມງ" />
                    <Tab style={{ fontFamily: "NotoSansLao" }} label="ກະເປົ່າ" />

                </Tabs>

            </Box>

            <Box style={{ padding: 10 }}>
                <Grid container spacing={2}>
                    {productLists?.map((item, index) =>
                        <Grid key={index} item xs={6} sm={3} md={2} lg={2}>
                            <Card sx={{ maxWidth: "100%", backgroundColor: "#ffffff", padding: 0, margin: 0 }} >
                                <CardActionArea>

                                    <LazyLoadImage
                                        component="img"
                                        minHeight="196"
                                        height="220"
                                        src={item?.image}
                                        PlaceholderSrc={ImageHover}
                                        alt="green iguana"
                                        onClick={() => viewDatil()}
                                        style={{ position: 'relative', width: "100%" }}

                                    />
                                    <TextHead sx={{
                                        top: 0, left: 20, textAlign: { xs: "center", sm: "center", md: "left", lg: "left" }, position: "absolute"
                                    }}>
                                        <Button variant="contained" sx={{ background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)", height: 30 }}>
                                            <AddShoppingCartIcon />
                                        </Button>
                                    </TextHead>
                                    <CardContent >
                                        <Typography gutterBottom component="div">
                                            <div className="price">{item?.price} ກີບ <span className="sub-price" style={{ fontWeight: "none" }}><del>{item?.discount} ກີບ</del></span></div>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <p className='detail'>{item?.name}</p>
                                            <CardFooter>
                                                <p className="detail">ສະຖານະ: <span className="h5">{item?.status}</span></p>
                                            </CardFooter>
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )}


                </Grid>

            </Box>

            {/* <MessengerCustomerChat
                    pageId="101436292064229"
                    appId="824300965285258"
                /> */}

        </div>
    )
}

export default Home