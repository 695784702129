import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

//funciton
import { formatCurrency, converStatus } from "../../hooks"

//component
import Empty from "../../components/Empty"



// router
import { PRODUCT_LIST, PRODUCT_DETAIL } from "../../constant/routes"
import { BACKEND_URL, IMAGE_URL, STORE_PRODUCT } from "../../constant/"

// import MessengerCustomerChat from 'react-messenger-customer-chat';
import { styled } from '@mui/material/styles';
import { Paper, Badge, Grid, Box, Typography, IconButton, AppBar, Toolbar, Stack, CardContent, CardActionArea, Slide } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ImageHover from '../../image/imageHover.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { LazyLoadImage } from "react-lazy-load-image-component";
import CircularProgress from '@material-ui/core/CircularProgress';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { get } from 'lodash';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


const Header = styled(Box)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
}));

const AppBarHead = styled(AppBar)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
    position: "static",
    // minHeight: "50px",
}));

const Box1 = styled("div")(({ theme }) => ({
    minHeight: "275px",
    position: "relative",
}));

const TextHead = styled("div")(({ theme }) => ({
    marginTop: 20
}));

const Card = styled(Paper)(({ theme }) => ({
    padding: 10,
    marginBottom: 15,
    fontSize: 18
}));
const BottomBox = styled("div")(({ theme }) => ({
    marginTop: 10,
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "#16C73C",
    color: "white",
    textAlign: "center",
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

const CardFooter = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
}))

const Quality = styled("div")(({ theme }) => ({
    display: "flex",
    marginTop: 30,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
}))

const ShopCard = styled("div")(({ theme }) => ({
    marginTop: 10,
    display: "flex",
    width: "87%",
    justifyContent: "end"
}));


const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 20,
        //   border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        color: "red",
        background: "#ffffff"

    },
}));




function ProductDetail() {
    // constant
    const Navigate = useNavigate();
    const params = useParams();
    const storeId = params.storeId
    const productId = params.productId

    // state
    const [proDetail, setProDetail] = useState(false);
    const [state, setState] = useState({ bottom: false, });
    const [selectSize, setSelectSize] = useState();
    const [selectColor, setSelectColor] = useState();
    const [quality, setQuality] = useState(0);
    const [products, setProducts] = useState([])

    const [productDetail, setProductDetail] = useState()
    const [orderChoose, setOrderChoose] = useState([])

    const [isLoading, setIsLoading] = useState()

    const [priceProduct, setPriceProduct] = useState(0)
    const [totalProduct, setTotalPrice] = useState(0)



    // useEffect
    useEffect(() => {
        var listOrder = sessionStorage.getItem(STORE_PRODUCT)
        if (listOrder !== null) setOrderChoose(JSON.parse(listOrder))

        setProDetail(true)
        getData(storeId, productId)
    }, [productId])


    const getData = async (storeId, productId) => {
        setIsLoading(true)
        // query detail product by id
        const responseProductDetail = await axios({
            method: 'get',
            url: BACKEND_URL + PRODUCT_DETAIL + productId
        })

        // query product
        const responseProduct = await axios({
            method: 'get',
            url: BACKEND_URL + PRODUCT_LIST + "?storeId=" + storeId
        })

        if (responseProductDetail?.data !== null && responseProduct?.data !== null) {
            setProductDetail(responseProductDetail?.data)
            setProducts(responseProduct?.data?.data)
            setIsLoading(false)
        }
    }



    useEffect(() => {
        if (quality < 0) setQuality(0)
        else setTotalPrice(quality * priceProduct)
    }, [quality])

    //function add news order
    const notifyError = (text) => toast.error(text, { autoClose: 1000, });
    const notifySuccess = (text) => toast.error(text, { autoClose: 1000, });

    const addNewOrder = () => {
        if (!selectSize) return notifyError("ກະລຸນາເລືອກຂະໜາດ")
        if (!selectColor) return notifyError("ກະລຸນາເລືອກສີ")
        if (!quality) return notifyError("ກະລຸນາເລືອກຈຳນວນ")

        let _data = {
            productId: productDetail?._id,
            storeId: productDetail?.storeId,
            images: productDetail?.images[0],
            price: parseInt(priceProduct),
            name: productDetail?.name,
            qty: quality,
            status: productDetail?.status,
            size: selectSize,
            color: selectColor,
            totalPrice: parseInt(totalProduct)
        }

        let newOrder = [...orderChoose]
        newOrder.push(_data)
        sessionStorage.setItem(STORE_PRODUCT, JSON.stringify(newOrder))
        notifySuccess("ອໍເດີສຳເລັດ")
        Navigate('/product/confirm-detail/' + storeId)
    }

    const notify = () => toast.success("ເພີ່ມເຂົ້າກະຕ່າແລ້ວ", { autoClose: 1000, });


    const onSelectSize = (e) => {
        let _size = e.target.value
        setSelectSize(_size)
        if (_size) {
            const getPrict = productDetail?.options.filter((item) => item?.sizeName === _size)
            setPriceProduct(getPrict[0].priceSize ?? 0)
        }
    }

    const onSelectColor = (e) => {
        let _color = e.target.value
        setSelectColor(_color)
    }

    const viewDatil = (productId) => {
        setSelectColor()
        setSelectSize()
        setTotalPrice(0)
        setQuality(0)
        setPriceProduct(0)
        Navigate('/product/detail/' + params.storeId + "/" + productId)
    }
    const backHome = () => { Navigate('/store/view-store/limit/50/skip/1/storeId/' + storeId) }
    const continuationOrder = () => { Navigate('/product/confirm-detail/' + params.storeId) }
    const viewProfile = () => { Navigate('/profile/' + params.storeId) }



    const properties = {
        prevArrow: <button style={{ display: "none" }}></button>,
        nextArrow: <button style={{ display: "none" }}></button>
    }

    return (
        <div>

            <Slide direction="left" in={proDetail} >
                <Grid container style={{ padding: 0, margin: 0 }}>
                    <Grid xs={12} sm={12} md={12} lg={12}>
                        <Header sx={{ flexGrow: 1 }} >
                            <AppBarHead sx={{ background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)" }}>
                                <Toolbar style={{display: "flex", flexDirection: "row", justifyContent:"space-between"}}>
                                    <IconButton
                                        onClick={() => backHome()}
                                        sx={{ fontWeight: "bold", color: '#ffffff', background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)" }}
                                        aria-label={`back`}
                                    >
                                        <ArrowBackIosNewIcon />
                                    </IconButton>
                                    <IconButton aria-label="cart" onClick={() => continuationOrder()} style={{ color: "#ffffff" }}>
                                        <StyledBadge badgeContent={orderChoose.length > 0 ? orderChoose.length : 0} color="secondary">
                                            <ShoppingCartIcon />
                                        </StyledBadge>
                                    </IconButton>
                                </Toolbar>
                            </AppBarHead>
                        </Header>
                    </Grid>

                    {isLoading ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", minHeight: "70vh" }}>
                            <CircularProgress style={{ color: "#16C73C" }} />
                        </div>
                    ) : (
                        <>
                            <Grid xs={12} sm={6} md={4} lg={4} style={{ padding: 10, margin: 0 }}>
                                {/* <Box1> */}
                                <center>
                                    <Fade autoplay={true}>
                                        {productDetail?.images.map((slideImage, index) => (
                                            <div className="each-fade" key={index}>
                                                <LazyLoadImage src={IMAGE_URL + slideImage} PlaceholderSrc={ImageHover} style={{ height: 300, borderRadius: "5%" }} />
                                            </div>
                                        ))}
                                    </Fade>

                                </center>

                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    spaceBetween={1}
                                    slidesPerView={5}
                                >
                                    {productDetail?.images.map((slideImage, index) => (
                                        <SwiperSlide key={index}>
                                            <LazyLoadImage src={IMAGE_URL + slideImage} PlaceholderSrc={ImageHover} style={{ height: '90px', objectFit: "cover", borderRadius: "5%" }} />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>

                            </Grid>
                            <Grid xs={12} sm={6} md={4} lg={4} style={{ padding: 10, margin: 0 }}>

                                <p className="h1">{productDetail?.name ?? ""}</p>
                                <div className="h1">{productDetail?.priceDiscount < productDetail?.price ? formatCurrency(productDetail?.priceDiscount) : formatCurrency(productDetail?.price)} ກີບ <span className="h2-1" style={{ fontWeight: "none" }}><del> {productDetail?.priceDiscount < productDetail?.price ? formatCurrency(productDetail?.price) : formatCurrency(productDetail?.priceDiscount)} ກີບ</del></span></div>
                                <CardFooter>
                                    <p className="h2-1">ສະຖານະ:</p>
                                    <p className="h2">{converStatus(productDetail?.status) ?? ""}</p>
                                </CardFooter>
                                <p className="h3" style={{ marginTop: 30 }}>ເລືອກຂະ​ໜາດ​ສິນ​ຄ້າ</p>

                                <Stack direction="row" spacing={2} sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: 3 }}>
                                    {productDetail?.options.map((size, index) =>
                                        <label key={index} style={{ border: `1px solid ${selectSize === size?.sizeName ? "#14A572" : "#D9D9D9"}`, borderRadius: "4px", padding: 13, minWidth: 20, textAlign: "center", cursor: 'pointer' }}>
                                            {size?.sizeName}
                                            <input type='checkbox' style={{ display: 'none' }} value={size?.sizeName} onChange={(e) => onSelectSize(e)} />
                                        </label>
                                    )}
                                </Stack>

                                <p className="h3" style={{ marginTop: 30 }}>ເລື​ອກ​ສີ</p>
                                <Stack direction="row" spacing={2} sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: 3 }}>
                                    {productDetail?.colors.map((color, index) =>
                                        <label key={index} style={{ border: `1px solid ${selectColor === color ? "#14A572" : "#D9D9D9"}`, borderRadius: "4px", padding: 13, minWidth: 20, textAlign: "center", cursor: 'pointer' }}>
                                            {color}
                                            <input type='checkbox' style={{ display: 'none' }} value={color} onChange={(e) => onSelectColor(e)} />
                                        </label>
                                    )}
                                </Stack>

                                <p className="h3" style={{ marginTop: 30 }}>ລາຍ​ລະ​ອຽດ​ສິນ​ຄ້າ</p>
                                <p style={{ color: "#6D6D6D", fontWeight: "400", fontSize: 16 }}>{productDetail?.detail ?? ""}</p>

                                <Quality>
                                    <p className="h3">ເລືອກຈຳ​ນວນ</p>
                                    <div>
                                        <button type='button' onClick={() => setQuality(quality - 1)} style={{ background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)", width: 50, border: 0, color: "#ffffff", borderRadius: "10%", fontWeight: "bold", cursor: "pointer" }}><RemoveCircleOutlineIcon style={{ paddingTop: 6 }} /></button>
                                        <span className="h3" style={{ padding: 20, fontSize: 25 }}>{quality}</span>
                                        <button type='button' onClick={() => setQuality(quality + 1)} style={{ background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)", width: 50, border: 0, color: "#ffffff", borderRadius: "10%", fontWeight: "bold", cursor: "pointer" }}><AddCircleOutlineIcon style={{ paddingTop: 6 }} /></button>
                                    </div>
                                </Quality>
                                <Quality>
                                    <p className="h3">ລາຄາຕາມຂະໜາດ</p>
                                    <p className="h3">{formatCurrency(priceProduct)} ກີບ</p>
                                </Quality>
                                <Quality>
                                    <p className="h3">ຈຳ​ນວນເງິນ​ທັງ​ໝົ​ດ</p>
                                    <p className="h3">{formatCurrency(totalProduct)} ກີບ</p>
                                </Quality>

                                {/* <button type='button' onClick={() => backHome()} style={{ marginTop: 10, borderRadius: "20px", fontWeight: "bold", background: "#efefef", width: "30%", border: 0, color: "#9A9A9A", padding: 15, fontSize: 20, fontWeight: "bold", cursor: "pointer" }}>ຍ້ອນກັບ</button>&nbsp; */}
                                <button type='button' onClick={() => addNewOrder()} style={{ marginTop: 10, borderRadius: "20px", fontWeight: "bold", background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)", width: "100%", border: 0, color: "#ffffff", padding: 15, fontSize: 20, fontWeight: "bold", cursor: "pointer" }}>ຕໍ່ໄປ</button>


                            </Grid>

                            <Grid xs={12} sm={12} md={4} lg={4} style={{ padding: 10, margin: 0, backgroundColor: "#F5F5F5" }}>
                                <p className="h3">ອັບ​ເດດ​ໃໝ່​ລ່າ​ສຸດ</p>

                                <Grid container spacing={2}>
                                    {products.length > 0 ? products?.map((item, index) =>
                                        <Grid key={index} item xs={6} sm={6} md={6} lg={6}>
                                            <Card sx={{ maxWidth: "100%", backgroundColor: "#ffffff", padding: 0, margin: 0 }} >
                                                <CardActionArea>
                                                    <LazyLoadImage
                                                        component="img"
                                                        minHeight="196"
                                                        height="220"
                                                        PlaceholderSrc={ImageHover}
                                                        src={IMAGE_URL + item?.images[0]}
                                                        alt="green iguana"
                                                        onClick={() => viewDatil(item?._id)}
                                                        style={{ position: 'relative', width: "100%", objectFit: "cover" }}

                                                    />
                                                    <ShopCard sx={{
                                                        top: 0, left: 20, textAlign: { xs: "center", sm: "center", md: "left", lg: "left" }, position: "absolute"
                                                    }}>

                                                    </ShopCard>
                                                    <CardContent >
                                                        <Typography gutterBottom component="div">
                                                            <div className="price">{item?.priceDiscount < item?.price ? formatCurrency(item?.priceDiscount) : formatCurrency(item?.price)} ກີບ <span className="sub-price" style={{ fontWeight: "none" }}><del>{item?.priceDiscount === 0 ? formatCurrency(item?.priceDiscount) : formatCurrency(item?.price)} ກີບ</del></span></div>
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            <p className='detail'>{item?.name}</p>
                                                            <CardFooter>
                                                                <p className="detail">ສະຖານະ: <span className="h5">{converStatus(item?.status) ?? "ໝົດແລ້ວ"}</span></p>
                                                            </CardFooter>
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ) : (
                                        <>
                                            <Empty />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    )}

                </Grid>
            </Slide>
            <ToastContainer />
        </div >
    )
}

export default ProductDetail