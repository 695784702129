import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import LoginPage from "../pages/Login.page";
import PrivateRoute from "./PrivateRoute";
import { Outlet } from "react-router-dom";
import HomePage from "../pages/home/";

import ProductsRoutes from "./Products.routes";
import HomeRoutes from "./MenuHome.routes"
import UserRoutes from "./User.routes"
import StoreRoutes from "./Store.routes"

// ------------------------ //

function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Outlet />,
      children: [
        { path: "/", element: <Navigate to="/store/view-store" /> },
        ProductsRoutes,
        HomeRoutes,
        UserRoutes,
        StoreRoutes
      ],
    },
    {
      path: "*",
      element: <Outlet />,
    },
  ]);
}

export default Router;
