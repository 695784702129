import React, { useState, useEffect } from 'react'
import { useNavigate,useParams } from "react-router-dom";
import { BACKEND_URL, IMAGE_URL, STORE_PRODUCT } from "../constant"


import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Typography from '@mui/material/Typography';



const Header = styled(Box)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
}));

const AppBarHead = styled(AppBar)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
    position: "static",
    // minHeight: "50px",
}));



function Navbar() {
    const Navigate = useNavigate();
    const params = useParams();

    const storeId = params.storeId
    const [orderChoose, setOrderChoose] = useState([])

       // useEffect
    useEffect(() => {
        var listOrder = sessionStorage.getItem(STORE_PRODUCT)
        if (listOrder !== null) setOrderChoose(JSON.parse(listOrder))
    }, [])

    const viewProfile = () => { Navigate('/profile') }
    const continuationOrder = () => { Navigate('/product/confirm-detail/' + storeId) }

    return (
        <>
            <Header sx={{ flexGrow: 1 }} >
                <AppBarHead sx={{ background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)" }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 1, }}
                            onClick={() => viewProfile()}
                        >
                            <AccountCircle sx={{ fontSize: { xs: 25, sm: 20, md: 20, lg: 24 } }} />
                        </IconButton>
                        <Typography variant="h6" onClick={() => viewProfile()} component="div" sx={{ flexGrow: 1, fontSize: { xs: 25, sm: 30, md: 20, lg: 24 }, fontWeight: 700 }}>
                            <p>ຮ້ານ ວັນ​ດີ ແຟ​ຊັ່ນ</p>
                        </Typography>
                        {/* <ShoppingCartIcon sx={{ fontSize: { xs: 25, sm: 20, md: 20, lg: 24 } }} /> */}
                        <ShoppingCartIcon onClick={() => continuationOrder()} sx={{ fontSize: { xs: 25, sm: 20, md: 24, lg: 28 } }} /> <font style={{ fontWeight: "bold", fontSize: 15, marginTop: 10, color: "#FF0000", backgroundColor: "#ffffff", borderRadius: "50%", paddingLeft: 4, paddingRight: 4 }}> {orderChoose.length > 0 ? orderChoose.length : 0} </font>
                    </Toolbar>
                </AppBarHead>
            </Header>
        </>
    )
}

export default Navbar