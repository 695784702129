import HomePage from "../pages/home/";

// eslint-disable-next-line
export default {
  path: "home",
  element: <HomePage />,
//   children: [
//     {
//       path: "",
//       element: <HomePage />,
//     },
    // {
    //   path: "add",
    //   element: <ProductsAddPage />,
    // },
//   ],
};
