import React from "react";
import { Context } from "./Context";
import  AuthState  from "./globalState/auth.state";
import ProductsState  from "./globalState/products.state";

export const StateProvider = ({ children }) => {
  const auth = AuthState();
  const products = ProductsState();
  let store = Object.assign(auth, products);
  return <Context.Provider value={store}>{children}</Context.Provider>;
};
