import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { STORE_INFO } from "../../constant/routes"
import { BACKEND_URL, IMAGE_URL } from "../../constant/"

//css
import { styled } from '@mui/material/styles';

import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Avatar from '@mui/material/Avatar';
import bgUser from '../../image/bgUser.jpg'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Header = styled("div")(({ theme }) => ({
    padding: 0,
    margin: 0,
    width: "100%",
    position: "relative",
}));

const TextHead = styled("div")(({ theme }) => ({
    marginTop: 20
}));

const BodyContent = styled("div")(({ theme }) => ({
    top: "85%",
    width: "100%",
    backgroundImage: `url(${bgUser})`,
    position: "absolute",
    padding: 0,
    margin: 0,
    backgroundPosition: "center", /* Center the image */
    backgroundRepeat: "no-repeat", /* Do not repeat the image */
    backgroundSize: "cover",
    borderRadius: "50px 50px 0px 0px",

}))

const Image = styled("div")(({ theme }) => ({
    position: "absolute",
    width: "100%",
    top: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    zIndex: "9999",
}));

const NameStore = styled("div")(({ theme }) => ({
    marginTop: "20px",
    textAlign: "center",
}));

const Version = styled("div")(({ theme }) => ({
    marginTop: "20px",
    textAlign: "center",
    fontSize: 13,
    color: '#ACAEBE',

}));

const Manual = styled("div")(({ theme }) => ({
    padding: 0,
    margin: 0,
    width: "100%",
    background: "none",
    borderBottom: "1px solid #D9D9D9",
    color: "#333333",
    fontWeight: "bold",
    fontSize: "14px",
    borderRadius: "0px 0px 30px 30px",
    cursor: "pointer",

}));



function Profile_page() {
    const Navigate = useNavigate();
    const params = useParams();

    const storeId = params.storeId
    const [isLoading, setIsLoading] = useState()

    const [profile, setProfile] = useState(false);
    const [storeInfo, setStoreInfo] = useState()

    useEffect(() => {
        setProfile(true)
        getStoreInfo(storeId)
    }, [storeId])

    const getStoreInfo = async (storeId) => {
        setIsLoading(true)
        //query product type
        const responseStore = await axios({
            method: 'get',
            url: BACKEND_URL + STORE_INFO + storeId
        })

        if (responseStore?.data !== null) {
            setStoreInfo(responseStore?.data)
            console.log("responseStore?.data", responseStore?.data)
            setIsLoading(false)
        }

    }

    const openWhatsApp = () => {
        window.location.href = `https://wa.me/+85620` + storeInfo?.contacts?.whatsapp + `/?text=ສະບາຍດີ`;
    }
    const openTelephone = () => {
        window.location.href = `tel:${storeInfo?.contacts?.phone}`;
    }
    const openFacebook = () => {
        window.location.href = `${storeInfo?.contacts?.fb}`;
    }

    const backHome = () => { Navigate('/store/view-store/limit/50/skip/1/storeId/' + storeId) }



    return (
        <>
            <Slide direction="down" in={profile} >
                <Header>
                    <img src={IMAGE_URL + storeInfo?.image}
                        style={{ width: "100%", height: 250, objectFit: "cover", }}
                    />
                    <TextHead sx={{
                        top: 0, left: 20, textAlign: { xs: "center", sm: "center", md: "left", lg: "left" }, position: "absolute"
                    }}>
                        <IconButton
                            onClick={() => backHome()}
                            sx={{ fontWeight: "bold", color: '#ffffff', background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)" }}
                            aria-label={`back`}
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                    </TextHead>

                    <Image>
                        <center>
                            <img src={IMAGE_URL + storeInfo?.image}
                                style={{ width: 160, height: 160, borderRadius: "50%", border: "6px solid #ffffff" }}
                                alt={storeInfo?.name ?? "-"}
                           />
                        </center>
                    </Image>

                    <BodyContent>
                        <Box sx={{ marginTop: { xs: "25%", md: "6%", padding: 10 } }}>
                            <p className='h3'>ຂໍ້​ມູນ​ທົ່ວ​ໄປ​ຂອງ​ຮ້ານ​ຄ້າ</p>
                            <NameStore>
                                <p className='h1'>{storeInfo?.name ?? "-"}</p>
                                <p className='h2-1'>ເບີ​ໂທ: {storeInfo?.contacts?.whatsapp ?? "-"}</p>
                                <p className='h2-1'>ສະ​ຖານ​ທີ່: {storeInfo?.address ?? "-"}</p>
                            </NameStore>
                            <p className='h3' style={{ marginTop: 15 }}>ຂໍ້​ມູນຕິ​ດ​ຕໍ່ຂອງ​ຮ້ານ​ຄ້າ</p>

                            <Stack direction="row" spacing={2} sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
                                <Button onClick={() => openTelephone()} style={{ color: "#ffffff", width: 110, height: 40, background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)", border: "1px solid #FFFFFF", boxShadow: "0px 0px 4px #D9D9D9", borderRadius: "12px" }} ><PhoneIcon /></Button>
                                <Button onClick={() => openWhatsApp()} style={{ color: "#ffffff", width: 110, height: 40, background: "#4CC85A", border: "1px solid #FFFFFF", boxShadow: "0px 0px 4px #D9D9D9", borderRadius: "12px" }} ><WhatsAppIcon /></Button>
                                <Button onClick={() => openFacebook()} style={{ color: "#ffffff", width: 110, height: 40, background: "#1877F2", border: "1px solid #FFFFFF", boxShadow: "0px 0px 4px #D9D9D9", borderRadius: "12px" }} ><FacebookIcon /></Button>
                            </Stack>

                            <p className='h3' style={{ marginTop: 15 }}>ກ່ຽວ​ກັບຮ້ານ​ຄ້າ</p>
                            <p style={{ color: "#6D6D6D", fontWeight: "400", fontSize: 16 }}>{storeInfo?.detail ?? "-"}</p>
                            <p className='h3' style={{ marginTop: 15 }}>ຄູ່ມ​ື​ການ​ນຳ​ໃຊ້</p>

                            <Manual>
                                <List>
                                    <ListItem
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete">
                                                <ArrowForwardIosIcon />
                                            </IconButton>
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LibraryBooksIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="ເຂ​ົ້າ​ອ່ານ​ຄູ່​ມື​ການ​ໃຊ້​ງານ"
                                        />
                                    </ListItem>,
                                </List>
                            </Manual>
                            <Version>
                                <p>Version 1.0</p>
                                <p>Powered by UNIMARKET</p>
                            </Version>



                        </Box>
                    </BodyContent>


                </Header>





            </Slide>


        </>
    )
}

export default Profile_page