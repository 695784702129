// material
import { ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useEffect } from "react";
import { useStore } from "./providers";
// router
import Routers from "./routers";
// theme
import theme from "./themes";
import { StateProvider } from "./providers";
import './App.css'

// ---------------------------------------- //
function App() {
  
  return (
    <ThemeProvider theme={theme}>
      <StateProvider>
        <Routers />
      </StateProvider>
    </ThemeProvider>
  );
}

export default App;
