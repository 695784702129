import { Outlet } from "react-router-dom";
import ProductsPage from "../pages/product/Products.page";
import ProductsAddPage from "../pages/product/ProductAdd.page";
import ProductDetail from "../pages/product/ProductDetail.page"
import ConfirmOrder from "../pages/product/ConfirmOrder.page"

// eslint-disable-next-line
export default {
  path: "product",
  element: <Outlet />,
  children: [
    {
      path: "product",
      element: <ProductsPage />,
    },
    {
      path: "add",
      element: <ProductsAddPage />,
    },
    {
      path: "detail/:storeId/:productId",
      element: <ProductDetail />,
    },
    {
      path: "confirm-detail/:storeId",
      element: <ConfirmOrder />,
    },
  ], 
};
