import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
// import MessengerCustomerChat from 'react-messenger-customer-chat';
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//function
import { formatCurrency, converStatus } from "../../hooks"

//component
import Empty from "../../components/Empty"
import Pagination from "../../hooks/PaginationHelp";


// router
import { PRODUCT_TYPE_STORE, PRODUCT_LIST, STORE_INFO } from "../../constant/routes"
import { BACKEND_URL, IMAGE_URL, STORE_PRODUCT } from "../../constant/"

import { styled } from '@mui/material/styles';
import { AppBar, Box, Badge, Stack, IconButton, Toolbar, Typography, Button, Card, CardContent, CardActionArea, Grid, DialogContent, Dialog } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import '../home/Home.css'
import ImageSlide from "../../image/banner.jpg"
import ImageHover from '../../image/imageHover.png'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CircularProgress from '@material-ui/core/CircularProgress';

const AppBarHead = styled(AppBar)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
    position: "static",
    minHeight: "50px",
}));

const Header = styled(Box)(({ theme }) => ({
    backgroundColor: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)",
    position: "relative",
}));

const BoxSearch = styled("div")(({ theme }) => ({
    padding: 10,
    position: "absolute",
    width: "100%"
}))
const CardFooter = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
}))

const TextHead = styled("div")(({ theme }) => ({
    marginTop: 10,
    display: "flex",
    width: "87%",
    justifyContent: "end"
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 20,
        //   border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        color: "red",
        background: "#ffffff"

    },
}));




function StorePage() {
    const Navigate = useNavigate();
    const { _limit, _skip, Pagination_helper } = Pagination();

    const params = useParams();
    const [storeInfo, setStoreInfo] = useState()

    const [isActive, setIsActive] = useState("ALL");
    const [productTypeId, setProductTypeId] = useState("ALL");
    const handleChange = (newValue) => {
        setProductTypeId(newValue);
        setIsActive(newValue);
    };

    //state
    const [isLoading, setIsLoading] = useState()
    const [isLoadingStore, setIsLoadingStore] = useState()


    const [orderChoose, setOrderChoose] = useState([])
    const [productTotal, setProductTotal] = useState(0)

    const [productType, setProductTypes] = useState([])
    const [products, setProducts] = useState([])
    //filter
    const [productName, setProductName] = useState("")

    //select detail
    const [showDetailProduct, setShowDetailProduct] = useState(false)
    const [detailProduct, setDetailProduct] = useState()
    const [selectSize, setSelectSize] = useState();
    const [selectColor, setSelectColor] = useState();
    const [priceProduct, setPriceProduct] = useState(0)
    // useEffect

    useEffect(() => {
        var listOrder = sessionStorage.getItem(STORE_PRODUCT)
        if (listOrder !== null) setOrderChoose(JSON.parse(listOrder))

        if (params.storeId) {
            getDates(params.storeId)
            // getStore(params.storeId)
        }
    }, [params])


    useEffect(() => {
        setIsLoading(true)
        let _filter = `?storeId=${params?.storeId}${productTypeId !== "ALL" ? `&type=${productTypeId}` : ""}${productName ? `&name=${productName}` : ""}`

        var axios = require('axios');
        var config = {
            method: 'get',
            url: BACKEND_URL + PRODUCT_LIST + _filter,
        };

        axios(config)
            .then(function (response) {
                setProductTotal(JSON.parse(JSON.stringify(response?.data?.total)))
                setProducts(JSON.parse(JSON.stringify(response?.data?.data)))
                setIsLoading(false)
            })
            .catch(function (error) {
                console.log(error);
                setIsLoading(false)
            });


    }, [productTypeId, params, productName,_limit, _skip])


    const getStore = async (id) => {
        setIsLoadingStore(true)

        const responseStore = await axios({
            method: 'get',
            url: BACKEND_URL + STORE_INFO + id
        })

        if (responseStore?.data !== null) {
            setStoreInfo(responseStore?.data)
            setIsLoadingStore(false)
        }
    }


    const getDates = async (storeId) => {
        setIsLoading(true)

        //query product type
        const responseProductType = await axios({
            method: 'get',
            url: BACKEND_URL + PRODUCT_TYPE_STORE + storeId
        })

        // query product
        const responseProduct = await axios({
            method: 'get',
            url: BACKEND_URL + PRODUCT_LIST + `?storeId=`+ storeId
        })

        //query product type
        const responseStore = await axios({
            method: 'get',
            url: BACKEND_URL + STORE_INFO + storeId
        })

        if (responseProductType?.data !== null && responseProduct?.data !== null && responseStore?.data !== null) {
            setProductTypes(responseProductType?.data)
            setProducts(responseProduct?.data?.data)
            setProductTotal(responseProduct?.data?.total)
            setStoreInfo(responseStore?.data)
            setIsLoading(false)
        }
    }
    const images = [
        {
            url: ImageSlide,
            caption: 'Slide 1'
        },
        {
            url: ImageSlide,
            caption: 'Slide 2'
        },
        {
            url: ImageSlide,
            caption: 'Slide 3'
        },
    ];

    const properties = {
        prevArrow: <button style={{ display: "none" }}></button>,
        nextArrow: <button style={{ display: "none" }}></button>
    }

    const onKeySearch = (e) => {
        setProductName(e.target.value)
    }

    const notifyError = (text) => toast.error(text, { autoClose: 1000, });
    const notify = () => toast.success("ເພີ່ມເຂົ້າກະຕ່າແລ້ວ", { autoClose: 1000, });

    const _addTOCart = async (item) => {
        if (!selectSize) return notifyError("ກະລຸນາເລືອກຂະໜາດ")
        if (!selectColor) return notifyError("ກະລຸນາເລືອກສີ")

        let data = {
            productId: detailProduct?._id,
            storeId: detailProduct?.storeId,
            images: detailProduct?.images[0],
            price: parseInt(priceProduct),
            name: detailProduct?.name,
            qty: 1,
            status: detailProduct?.status,
            size: selectSize,
            color: selectColor,
            totalPrice: parseInt(priceProduct)
        }
        let newOrder = [...orderChoose]
        newOrder.push(data)
        await sessionStorage.setItem(STORE_PRODUCT, JSON.stringify(newOrder))
        await notify()
        await setDetailProduct()
        await setSelectColor()
        await setSelectSize()
        await setPriceProduct()
        await setShowDetailProduct(false)
        var listOrder = await sessionStorage.getItem(STORE_PRODUCT)
        if (listOrder !== null) setOrderChoose(JSON.parse(listOrder))
    }




    const viewDatil = (productId) => { Navigate('/product/detail/' + params.storeId + "/" + productId) }
    const viewProfile = () => { Navigate('/profile/' + params.storeId) }
    const continuationOrder = () => { Navigate('/product/confirm-detail/' + params.storeId) }

    const onSelectSize = (e) => {
        let _size = e.target.value
        setSelectSize(_size)
        if (_size) {
            const getPrict = detailProduct?.options.filter((item) => item?.sizeName === _size)
            setPriceProduct(getPrict[0].priceSize ?? 0)
        }


    }
    const onSelectColor = (e) => {
        let _color = e.target.value
        setSelectColor(_color)
    }

    const handleCloseDetail = () => {
        setDetailProduct()
        setSelectColor()
        setSelectSize()
        setPriceProduct()
        setShowDetailProduct(false)
    }

    return (
        <div>
            <Header sx={{ flexGrow: 1 }} >
                <AppBarHead sx={{ background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)", minHeight: "100px" }}>
                    <Toolbar sx={{ marginTop: { xs: 2.5, sm: 3, md: 1.5, lg: 1.5 }, cursor: 'pointer' }}>
                        {isLoadingStore ? (
                            <div>
                                <CircularProgress style={{ color: "#16C73C" }} />
                            </div>
                        ) : (
                            <>
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 1, }}
                                    onClick={() => viewProfile()}
                                >
                                    <LazyLoadImage
                                        src={IMAGE_URL + storeInfo?.image}
                                        style={{ width: 40, height: 40, borderRadius: "50%", border: "2px solid #ffffff" }}
                                        alt={storeInfo?.name ?? "-"}
                                        PlaceholderSrc={ImageHover}
                                    />
                                    {/* <AccountCircle sx={{ fontSize: { xs: 25, sm: 20, md: 20, lg: 24 } }} /> */}
                                </IconButton>
                                <Typography variant="h6" onClick={() => viewProfile()} component="div" sx={{ flexGrow: 1, fontSize: { xs: 25, sm: 30, md: 20, lg: 24 }, fontWeight: 700 }}>
                                    <p>{storeInfo?.name}</p>
                                </Typography>
                            </>
                        )}
                        {/* <ShoppingCartIcon onClick={() => continuationOrder()} sx={{ fontSize: { xs: 25, sm: 20, md: 24, lg: 28 } }} /> <font style={{ fontWeight: "bold", fontSize: 15, marginTop: 10, color: "#FF0000", backgroundColor: "#ffffff", borderRadius: "50%", paddingLeft: 4, paddingRight: 4 }}> {orderChoose.length > 0 ? orderChoose.length : 0} </font> */}

                        <IconButton aria-label="cart" onClick={() => continuationOrder()} style={{ color: "#ffffff" }}>
                            <StyledBadge badgeContent={orderChoose.length > 0 ? orderChoose.length : 0} color="secondary">
                                <ShoppingCartIcon />
                            </StyledBadge>
                        </IconButton>


                    </Toolbar>
                </AppBarHead>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: { xs: '-4%', sm: "-3%", md: "-1.5%", lg: '-2%' } }}>
                    <input type="text" onChange={(e) => onKeySearch(e)} className='searchBox' placeholder="ຄົ້ນຫາ..." style={{ width: "90%", height: "45px", borderRadius: "16px", boxShadow: "0px 4px 4px rgba(113, 113, 113, 0.07)", background: "#FFFFFF", border: 0 }} />
                </Box>
            </Header>

            <Box style={{ padding: 10 }}>
                <div className="slide-container" style={{ marginTop: 18 }}>
                    <Slide {...properties}>
                        {images.map((slideImage, index) => (
                            <div className="each-slide" key={index}>
                                <LazyLoadImage src={slideImage.url} PlaceholderSrc={ImageHover} style={{ width: "100%", borderRadius: "10px" }} alt="ads" />
                            </div>
                        ))}
                    </Slide>
                </div>
            </Box>
            {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", minHeight: "70vh" }}>
                    <CircularProgress style={{ color: "#16C73C" }} />
                </div>
            ) : (
                <>
                    {/* ໝວດສິນຄ້າ */}
                    <Box style={{ padding: 10 }}>
                        <p className="h1" style={{}}>ໝວດສິນຄ້າ</p>


                        <div
                            style={{
                                display: "flex",
                                overflowY: "scroll",
                                justifyContent: "start",
                                paddingBottom: "10px",
                                paddingTop: "20px",
                            }}
                        >

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.06)",
                                        height: "40px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        minWidth: "80px",
                                        paddingLeft:10,
                                        paddingRight:10,
                                        borderRadius: "10px",
                                        marginLeft: "10px",
                                        fontSize: "16px",
                                        background: isActive === "ALL" ? 'linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)' : '',
                                        color: isActive === "ALL" ? "#ffffff" : "#6D6D6D",
                                        fontWeight: isActive === "ALL" ? "bold" : "",
                                    }}
                                    onClick={() => handleChange("ALL")}
                                >
                                    <span>
                                        ທັງໝົດ
                                    </span>
                                </div>
                            </div>
                            <span style={{ width: "15px" }}></span>



                            {productType &&
                                productType.map((productType, index) => (
                                    <>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            key={"type" + index}
                                        >
                                            <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.06)",
                                                height: "40px",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "130px",
                                                // width:"auto",
                                                paddingLeft:10,
                                                paddingRight:10,
                                                borderRadius: "10px",
                                                marginLeft: "10px",
                                                fontSize: "16px",
                                                background: isActive === productType?._id ? 'linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)' : '',
                                                color: isActive === productType?._id ? "#ffffff" : "#6D6D6D",
                                                fontWeight: isActive === productType?._id ? "bold" : "",
                                            }}
                                                onClick={() => handleChange(productType?._id)}
                                            >
                                                <span  >
                                                    {productType?.name}
                                                </span>
                                            </div>
                                        </div>
                                        <span style={{ width: "20px" }}></span>
                                    </>
                                ))}

                        </div>







                    </Box>
                    {/* ໝວດສິນຄ້າ */}
                    {/* ສິນຄ້າ */}
                    <Box style={{ padding: 10 }}>
                        <Grid container spacing={2}>
                            {products.length > 0 ? products?.map((item, index) =>
                                <Grid key={index} item xs={6} sm={3} md={2} lg={2}>
                                    <Card sx={{ maxWidth: "100%", backgroundColor: "#ffffff", padding: 0, margin: 0 }} >
                                        <CardActionArea>

                                            <LazyLoadImage
                                                component="img"
                                                src={IMAGE_URL + item?.images[0]}
                                                PlaceholderSrc={ImageHover}
                                                alt="green iguana"
                                                onClick={() => viewDatil(item?._id)}
                                                style={{ position: 'relative', width: "100%", height: "220px", objectFit: "cover" }}

                                            />
                                            <TextHead sx={{
                                                top: 0, left: 20, textAlign: { xs: "center", sm: "center", md: "left", lg: "left" }, position: "absolute"
                                            }}>
                                                <Button variant="contained" onClick={() => { setShowDetailProduct(true); setDetailProduct(item) }} sx={{ background: "linear-gradient(90deg, #14A572 -1.54%, #3BEEAE 100%)", height: 30 }}>
                                                    <AddShoppingCartIcon />
                                                </Button>
                                            </TextHead>
                                            <CardContent >
                                                {/* <Typography gutterBottom component="div"> */}
                                                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                    <p className="price">{item?.priceDiscount < item?.price ? formatCurrency(item?.priceDiscount) : formatCurrency(item?.price)} ກີບ</p>
                                                    &nbsp;
                                                    <p className="sub-price">
                                                        <del>
                                                            {item?.priceDiscount === 0 ? formatCurrency(item?.priceDiscount) : formatCurrency(item?.price)}ກີບ
                                                        </del>
                                                    </p>
                                                </div>
                                                {/* </Typography> */}
                                                {/* <Typography variant="body2" color="text.secondary"> */}
                                                <p className='detail'>{item?.name}</p>
                                                <CardFooter>
                                                    <p className="detail">ສະຖານະ: <span className="h5">{converStatus(item?.status) ?? ""}</span></p>
                                                </CardFooter>
                                                {/* </Typography> */}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ) : (
                                <>
                                    <Empty />
                                </>
                            )}


                        </Grid>

                    </Box>
                    {/* ສິນຄ້າ */}
                    {/* {productTotal <= 0 ? "" : (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
                            {Pagination_helper(productTotal, "/store/view-store",params.storeId)}
                        </div>
                    )} */}
                </>
            )}

            <Dialog open={showDetailProduct} maxWidth="sm" fullWidth={true} >
                <DialogContent style={{ background: "linear-gradient(90deg, #14A572 0%, #3BEEAE 100%)" }}>
                    <div style={{ textAlign: "center", color: "#ffffff", fontWeight: "bold" }}>
                        <p style={{ fontSize: 24 }}>ກະ​ລຸ​ນາເລືອກ​ຂໍ້​ມູນລຸ່ມ​ນີ້:</p>
                        <p style={{ fontSize: 16 }}>ຂະ​ໜາດ​ສິນ​ຄ້າ</p>
                    </div>

                    <Stack direction="row" spacing={2} sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: 1 }}>
                        {detailProduct?.options.map((size, index) =>
                            <label key={index} style={{
                                color: selectSize === size?.sizeName ? "#ffffff" : "#6D6D6D",
                                border: `1px solid ${selectSize === size?.sizeName ? "#14A572" : "#D9D9D9"}`,
                                borderRadius: "4px", padding: 10, minWidth: 20, textAlign: "center", cursor: 'pointer',
                                backgroundColor: selectSize === size?.sizeName ? "#14A572" : "#ffffff"
                            }}>
                                {size?.sizeName}
                                <input type='checkbox' style={{ display: 'none' }} value={size?.sizeName}
                                    onChange={(e) => onSelectSize(e)
                                    }
                                />
                            </label>
                        )}
                    </Stack>

                    <div style={{ textAlign: "center", color: "#ffffff", fontWeight: "bold", marginTop: 4 }}>
                        <p style={{ fontSize: 16 }}>ເລື​ອກ​ສີ</p>
                    </div>

                    <Stack direction="row" spacing={2} sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: 1 }}>
                        {detailProduct?.colors.map((color, index) =>
                            <label key={index} style={{
                                color: selectColor === color ? "#ffffff" : "#6D6D6D",
                                border: `1px solid ${selectColor === color ? "#14A572" : "#D9D9D9"}`,
                                backgroundColor: selectColor === color ? "#14A572" : "#ffffff",
                                borderRadius: "4px", padding: 10, minWidth: 20, textAlign: "center", cursor: 'pointer'
                            }}>
                                {color}
                                <input type='checkbox' style={{ display: 'none' }} value={color} onChange={(e) => onSelectColor(e)} />
                            </label>
                        )}

                    </Stack>
                    <div style={{ height: 20 }}></div>
                    <center>
                        <button type="button" className='cancelBtn' onClick={() => handleCloseDetail()}>ຍົກເລີກ</button>
                        &nbsp;
                        <button type="button" className='confirmBtn' onClick={() => _addTOCart()}>ຕົກລົງ</button>
                    </center>
                </DialogContent>
            </Dialog>

            <ToastContainer />
        </div>
    )
}

export default StorePage